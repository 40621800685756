import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Tooltip,
} from '@mui/material';
import { AxiosResponse } from 'axios';

import usePlaylists from '@/hooks/playlists/usePlaylists';
import usePlayTrackPreview from '@/hooks/usePlayTrackPreview';
import { useRefetchAfterReview } from '@/hooks/useRefetchAfterReview';
import { ValidTrackPlacementModel } from '@/models/PlaylistInterface';
import { pitchTrackArtist, PitchTrackModel } from '@/models/TrackInterfaces';
import PitchesAPI from '@/network/PitchesAPI';
import PlaylistAPI from '@/network/PlaylistAPI';

import AppContext, { SnackbarContextModel } from '../../contexts/AppContext';
interface IFeedbackModalProps {
  tracks: PitchTrackModel[];
  accept: boolean | undefined;
  closeModalOutput: (event: boolean) => void;
  updateTracks: () => void;
}

type Touched = {
  [key: string]: string | boolean;
};

export default function FeedbackModal({ tracks, closeModalOutput, accept, updateTracks }: IFeedbackModalProps) {
  const [index, setIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [touched, setTouched] = useState<Touched>({});
  const [differentPlaylist, setDifferentPlaylist] = useState<boolean>(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState<string>('');
  const { refetchAfterReview } = useRefetchAfterReview();

  const { trackPreviewUrl, setTrackPreviewUrl } = usePlayTrackPreview();

  const { dispatchSnackbar } = useContext<SnackbarContextModel>(AppContext);

  const [productionQuality, setProductionQuality] = useState<number | number[] | null>(0);
  const [trackAppeal, setTrackAppeal] = useState<number | number[] | null>(0);
  const [playlistMoodMatch, setPlaylistMoodMatch] = useState<number | number[] | null>(0);
  const [feedbackComment, setFeedbackComment] = useState<string>('');
  const { playlists } = usePlaylists({
    pageNumber: 1,
    sortBy: 'name',
    orderBy: 'asc',
    status: 'active',
    pageSize: 200,
  });

  const closeModal = () => {
    updateTracks();
    setTrackPreviewUrl(undefined);
    closeModalOutput(false);
  };

  useEffect(() => {
    setIndex(0);
  }, [tracks]);

  useEffect(() => {
    setTouched({});
  }, [index]);

  const acceptTrack = (track: PitchTrackModel) => {
    setIsLoading(true);

    addToSpotifyPlaylist(track)
      .then((resp: AxiosResponse<ValidTrackPlacementModel>) => {
        if (resp.data.validTrackPlacement) {
          dispatchSnackbar({
            type: 'OPEN_SNACKBAR',
            payload: {
              message: `${track.track.name} has been added to your playlist`,
              type: 'success',
            },
          });
          sendReview(track);
        } else {
          dispatchSnackbar({
            type: 'OPEN_SNACKBAR',
            payload: {
              message: `We couldn't find this track on this playlist. Add the track and try again.`,
              type: 'error',
            },
          });
          setIsLoading(false);
        }
      })
      .catch(() => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: `Oops...something went wrong, try again`,
            type: 'error',
          },
        });
        setIsLoading(false);
      });
  };

  const addToSpotifyPlaylist = (track: PitchTrackModel) => {
    if (selectedPlaylist) {
      return PlaylistAPI.verifyTrackPlaylistPlacement({ playlistId: selectedPlaylist, trackId: track.track.spotifyId });
    }

    const playlistId = track.playlist.spotifyId;
    return PlaylistAPI.verifyTrackPlaylistPlacement({ playlistId: playlistId, trackId: track.track.spotifyId });
  };

  const checkFeedback = () => {
    if (differentPlaylist) {
      return (
        `**YOUR TRACK WAS ADDED TO A DIFFERENT PLAYLIST**\n\nThe curator has added your track to a different playlist they think it's a better fit for, find it here: https://open.spotify.com/playlist/${selectedPlaylist}\n\n` +
        feedbackComment
      );
    }
    return feedbackComment;
  };

  const sendReview = (track: PitchTrackModel) => {
    const data = {
      accepted: accept,
      curatorSpotifyId: track.playlist.curatorSpotifyId,
      playlistSpotifyId: track.playlist.spotifyId,
      curatorPitchId: track.id,
      artistAccountId: track.artistAccountId,
      feedback: {
        productionQuality: productionQuality,
        trackAppeal: trackAppeal,
        moodMatch: playlistMoodMatch,
        feedback: checkFeedback(),
      },
    };

    PitchesAPI.sendPitchReview(data).then(() => {
      refetchAfterReview();
      index === tracks.length - 1 ? closeModal() : setIndex(index + 1);
      resetSliders();
      setFeedbackComment('');
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: `Review for ${track.track.name} successfully sent`,
          type: 'success',
        },
      });
      setIsLoading(false);
    });
  };

  const rejectTrack = (track: PitchTrackModel) => {
    setIsLoading(true);
    if (differentPlaylist && selectedPlaylist === '') {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: `Please select a playlist to add this track to`,
          type: 'error',
        },
      });
      setIsLoading(false);
      return;
    }
    if (differentPlaylist) {
      addToSpotifyPlaylist(track)
        .then((resp: AxiosResponse<ValidTrackPlacementModel>) => {
          if (resp.data.validTrackPlacement) {
            sendReview(track);
          } else {
            dispatchSnackbar({
              type: 'OPEN_SNACKBAR',
              payload: {
                message: `We couldn't find this track on this playlist. Add the track and try again.`,
                type: 'error',
              },
            });
            setIsLoading(false);
          }
        })
        .catch(() => {
          dispatchSnackbar({
            type: 'OPEN_SNACKBAR',
            payload: {
              message: `Oops...something went wrong, try again`,
              type: 'error',
            },
          });
          setIsLoading(false);
        });
      return;
    }
    sendReview(track);
  };

  const resetSliders = () => {
    setProductionQuality(0);
    setTrackAppeal(0);
    setPlaylistMoodMatch(0);
    setFeedbackComment('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTouched({
      ...touched,
      [name]: value,
    });
  };

  const updateFeedbackComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackComment(event.target.value);
  };

  const isDisabled = () => {
    return (
      touched.productionQuality === undefined ||
      touched.trackAppeal === undefined ||
      touched.playlistMoodMatch === undefined ||
      feedbackComment === '' ||
      isLoading
    );
  };

  return (
    <div className="feedback-modal-container">
      <div className="max-w200 m-auto mt48">
        <p className="text-blue">
          Song {index + 1} of {tracks.length}
        </p>
        <Slider value={(100 / tracks.length) * (index + 1)}></Slider>
      </div>
      <Button className="icon-btn add-playlist-close-btn" onClick={closeModal}>
        <Icon>close</Icon>
      </Button>

      <h1 className="mt48">Give us feedback</h1>
      <p className="text-faded mt16">
        This track was submitted by <span className="text-white">{tracks[index]?.artist?.name}</span> to the{' '}
        <span className="text-white">{tracks[index]?.playlist?.name}</span> playlist
      </p>
      <div
        className={`title-card ml-auto mr-auto max-w400 d-flex mt32 playback-pic ${trackPreviewUrl === tracks[index]?.track.previewUrl ? 'item-playing' : ''}`}
      >
        <img
          src={tracks[index]?.track?.image ? tracks[index]?.track?.image : 'images/logos/no-image-available.svg'}
          alt=""
        />
        <div className="text-left">
          <p className="small">{tracks[index]?.track?.name}</p>
          <p className="small text-faded">
            {tracks[index]?.track?.artists?.map((artist: pitchTrackArtist, artistIndex: number) =>
              artistIndex === tracks[index]?.track?.artists.length - 1 ? artist.name : artist.name + ', '
            ) || 'N/A'}
          </p>
        </div>
        <div className="ml-auto">
          <Tooltip title="Preview" arrow placement="top">
            <Button
              className="icon-btn ml0"
              onClick={() => {
                if (trackPreviewUrl === tracks[index]?.track.previewUrl) {
                  setTrackPreviewUrl(undefined);
                  return;
                }
                setTrackPreviewUrl(tracks[index]?.track.previewUrl);
              }}
            >
              {trackPreviewUrl !== tracks[index]?.track.previewUrl && <Icon>play_arrow</Icon>}
              {trackPreviewUrl === tracks[index]?.track.previewUrl && <Icon>pause</Icon>}
            </Button>
          </Tooltip>
          <Tooltip title="View on Spotify" arrow placement="top">
            <Button
              className="icon-btn ml0"
              onClick={() => {
                window.open(tracks[index]?.track.url);
              }}
            >
              <img src="/images/spotify-logo.png" alt="spot-logo"></img>
            </Button>
          </Tooltip>
        </div>
      </div>

      <form className="max-w400 ml-auto mr-auto mt32">
        <div className="mb16">
          <div className="d-flex jc-center">
            <p className="small">Production Quality</p>
            <Tooltip title="The production quality measure indicates whether you think the track has been mixed and mastered to a professional standard. Do all of the different musical elements work well together and meet your expectations.">
              <Icon className="material-symbols-outlined pl8 fs-16 mt4">info</Icon>
            </Tooltip>
          </div>
          <Slider
            defaultValue={0}
            step={1}
            max={5}
            min={0}
            valueLabelDisplay="auto"
            value={productionQuality || 0}
            name="productionQuality"
            onChange={(e: unknown, value) => {
              handleChange(e as React.ChangeEvent<HTMLInputElement>);
              setProductionQuality(value);
            }}
          ></Slider>
          <div className="d-flex jc-space-between mt-8">
            <p className="small">Low</p>
            <p className="small">High</p>
          </div>
        </div>
        <div className="mb16">
          <div className="d-flex jc-center">
            <p className="small">Track Appeal</p>
            <Tooltip title="Does the song appeal to the audience that it’s created for, does the track have good repeat play value according to your musical knowledge.">
              <Icon className="material-symbols-outlined pl8 fs-16 mt4">info</Icon>
            </Tooltip>
          </div>
          <Slider
            defaultValue={0}
            step={1}
            max={5}
            min={0}
            valueLabelDisplay="auto"
            value={trackAppeal || 0}
            name="trackAppeal"
            onChange={(e: unknown, value: number | number[]) => {
              handleChange(e as React.ChangeEvent<HTMLInputElement>);
              setTrackAppeal(value);
            }}
          ></Slider>
          <div className="d-flex jc-space-between mt-8">
            <p className="small">Low</p>
            <p className="small">High</p>
          </div>
        </div>
        <div className="mb16">
          <div className="d-flex jc-center">
            <p className="small">Playlist Mood Match</p>
            <Tooltip title="Does the song fit in with the vibe and emotion that you’re trying to create with the playlist. It may match by genre, but does it match by mood and feel.">
              <Icon className="material-symbols-outlined pl8 fs-16 mt4">info</Icon>
            </Tooltip>
          </div>
          <Slider
            defaultValue={0}
            step={1}
            max={5}
            min={0}
            valueLabelDisplay="auto"
            value={playlistMoodMatch || 0}
            name="playlistMoodMatch"
            onChange={(e: unknown, value: number | number[]) => {
              handleChange(e as React.ChangeEvent<HTMLInputElement>);
              setPlaylistMoodMatch(value);
            }}
          ></Slider>
          <div className="d-flex jc-space-between mt-8">
            <p className="small">Low</p>
            <p className="small">High</p>
          </div>
        </div>

        <div>
          <label>
            <p className="small text-center">Comments</p>
          </label>
          <textarea
            value={feedbackComment}
            onChange={updateFeedbackComment}
            placeholder="This field is required and will be sent directly to the artist - please be reasonable but constructive"
          />
        </div>
      </form>

      <div className="max-w400 m-auto">
        <FormControlLabel
          className="mt16 text-left"
          label="Add this track to a different playlist"
          control={
            <Checkbox
              id="playlist-checkbox"
              name="playlist-checkbox"
              icon={<div className="checkbox-icon"></div>}
              checkedIcon={
                <div className="checkbox-icon">
                  <div className="checked"></div>
                </div>
              }
              checked={differentPlaylist}
              onChange={() => {
                if (differentPlaylist) {
                  setSelectedPlaylist(playlists![0].platform.id);
                }
                setDifferentPlaylist(!differentPlaylist);
              }}
            />
          }
        />
        {differentPlaylist && (
          <p className="small text-faded mb16">
            Feedback is required to add this track to a different playlist. Please use the sliders above and write a
            comment.
          </p>
        )}
      </div>

      {differentPlaylist && (
        <FormControl className="mt16 mb16">
          <InputLabel>Choose a playlist to add to</InputLabel>
          <Select
            value={selectedPlaylist}
            onChange={(e) => {
              setSelectedPlaylist(e.target.value as string);
            }}
          >
            {playlists?.map((playlist) => (
              <MenuItem key={playlist.id} value={playlist.platform.id}>
                {playlist.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <br />
      {accept ? (
        <Button
          className="btn-white"
          disabled={isLoading || (differentPlaylist && isDisabled())}
          onClick={() => {
            acceptTrack(tracks[index]);
          }}
        >
          <span>Confirm track added to playlist</span>
        </Button>
      ) : (
        <Button
          className="btn-white"
          onClick={() => {
            rejectTrack(tracks[index]);
          }}
          disabled={isDisabled()}
        >
          <span className="btn-text">Reject & send feedback</span>
        </Button>
      )}
    </div>
  );
}
