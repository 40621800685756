import React, { useCallback, useContext, useReducer, useState } from 'react';

import { useAuth0, User } from '@auth0/auth0-react';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import Icon from '@mui/material/Icon';
import { AxiosResponse } from 'axios';

import useAccount from '@/hooks/useAccount';
import UserAPI from '@/network/UserAPI';

import OnboardingContext, { OnboardingContextModel } from '../../contexts/OnboardingContext';

export default function UserInfo() {
  const { user } = useAuth0();
  const { unhurdUser } = useAccount();

  const { dispatch } = useContext<OnboardingContextModel>(OnboardingContext);

  const initialFormState = {
    firstname: user?.given_name || '',
    lastname: user?.family_name || '',
    email: user?.email || '',
    businessName: '',
  };
  type FormState = {
    firstname: string;
    lastname: string;
    email: string;
    businessName: string;
  };

  type FormAction = {
    type: string;
    field: string;
    payload: string;
  };

  const formReducer = (state: FormState, action: FormAction): FormState => {
    switch (action.type) {
      case 'HANDLE INPUT TEXT':
        return {
          ...state,
          [action.field]: action.payload,
        };
      default:
        return state;
    }
  };

  const [formState, dispatchForm] = useReducer(formReducer, initialFormState);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [isSendingForm, setIsSendingForm] = useState<boolean>(false);

  const formUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatchForm({
      type: 'HANDLE INPUT TEXT',
      field: event.target.name,
      payload: event.target.value,
    });
  };

  const createUser = useCallback(() => {
    if (!user || !user.sub || !user.email) return;
    const data = {
      userId: user.sub,
      authenticationType: 0,
      email: user.email,
      firstName: formState.firstname || '',
      lastName: formState.lastname || '',
    };
    UserAPI.createUser(data).then((resp: AxiosResponse<User>) => {
      localStorage.setItem('userID', resp.data.userId);
      localStorage.setItem('unhurdID', resp.data.id);
      dispatch({
        type: 'PAGE NEXT',
      });
    });
  }, [dispatch, formState.firstname, formState.lastname, user]);

  const submitForm = async () => {
    if (!unhurdUser) return createUser();
    if (formState.firstname !== '' && formState.lastname !== '') {
      setIsSendingForm(true);
      const details = {
        userId: unhurdUser?.userId,
        id: unhurdUser?.id,
        firstName: formState.firstname,
        lastName: formState.lastname,
        businessName: formState.businessName,
        email: formState.email,
        onboardingStage: 1,
      };

      UserAPI.updateUserOnboarding({ auth0Id: unhurdUser?.userId, unhurdId: unhurdUser?.id, data: details })
        .then(() => {
          dispatch({
            type: 'PAGE NEXT',
          });
          setIsSendingForm(false);
        })
        .catch(() => {
          setIsSendingForm(false);
        });
    }
  };

  const formInvalid = () => {
    if (formState.firstname !== '' && formState.lastname !== '' && isTermsChecked) {
      return false;
    } else {
      return true;
    }
  };

  const handleCheckboxChange = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  return (
    <div>
      <h1 className="">Hi, tell us a little bit about yourself</h1>
      <h3 className="text-faded mt32">
        We’re glad you’re here, we just need to know a little bit about you before we can get started.{' '}
      </h3>
      <div className="user-info-form onboarding">
        <form>
          <div className="user-form-names">
            <label>
              <p>First name *</p>
              <input
                placeholder="John"
                name="firstname"
                value={formState.firstname}
                onChange={formUpdate}
                data-testid="firstname"
                required
              ></input>
            </label>
            <label>
              <p>Last name *</p>
              <input
                placeholder="Smith"
                name="lastname"
                value={formState.lastname}
                onChange={formUpdate}
                data-testid="lastname"
                required
              ></input>
            </label>
          </div>
          <label>
            <p>Business name</p>
            <input
              placeholder="Business name (optional)"
              name="businessName"
              value={formState.businessName}
              onChange={formUpdate}
            ></input>
          </label>
          <label>
            <p className="mt16">Email address</p>
            <input
              placeholder="Enter email address"
              name="email"
              disabled={true}
              value={formState.email}
              onChange={formUpdate}
            ></input>
          </label>
          <FormControlLabel
            className="mt16"
            label={
              <p className="pt8">
                Click here to agree to our{' '}
                <span>
                  <a href="https://www.unhurdmusic.com/partnerterms" target="blank">
                    Terms & Conditions
                  </a>
                </span>
              </p>
            }
            control={
              <Checkbox
                id="playlist-checkbox"
                data-testid="ts-and-cs-checkbox"
                name="playlist-checkbox"
                icon={<div className="checkbox-icon"></div>}
                checkedIcon={
                  <div className="checkbox-icon">
                    <div className="checked"></div>
                  </div>
                }
                onChange={() => {
                  handleCheckboxChange();
                }}
                value={false}
              />
            }
          />
        </form>
      </div>
      <div className="onboarding-nav-container">
        <div className="onboarding-nav-buttons">
          <div className="ml-auto">
            <Button
              disabled={formInvalid() || isSendingForm}
              className="mt32 btn-white"
              onClick={() => {
                submitForm();
              }}
              data-testid="submit-user-data"
            >
              <span className="btn-text icon-suffix">Continue</span>
              <Icon>chevron_right</Icon>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
