import React, { useCallback, useEffect, useState } from 'react';

import useAllPitches from '@/hooks/pitches/useAllPitches';
import { PitchTrackModel } from '@/models/TrackInterfaces';

import Loading from '../utility/Loading';
import PitchesTable from './PitchesTable';

export default function AllPitchesTable({
  outputSelectedTracks,
}: {
  outputSelectedTracks: (tracks: PitchTrackModel[]) => void;
}) {
  const [selectedTracks, setSelectedTracks] = useState<PitchTrackModel[]>([]);
  const [tracks, setTracks] = useState<PitchTrackModel[]>([]);
  const [loadMoreToken, setLoadMoreToken] = useState<string>();
  const [contToken, setContToken] = useState<string>();
  const [trackLoading, setTracksLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('date');
  const [order, setOrder] = useState<'desc' | 'asc'>('desc');

  const { pitches, pitchesIsLoading, totalPitches, pitchesContToken, refetchPitches } = useAllPitches({
    status: 'pending',
    contToken: loadMoreToken,
    sortedBy: sortBy,
    orderedBy: order,
  });

  useEffect(() => {
    outputSelectedTracks(selectedTracks);
  }, [outputSelectedTracks, selectedTracks]);

  useEffect(() => {
    if (!pitches || pitchesIsLoading) return;

    setTracks((tracks) => [...tracks, ...pitches]);
    setTracksLoading(false);
    setIsLoadingMore(false);
  }, [pitches, pitchesIsLoading]);

  useEffect(() => {
    if (pitchesContToken) {
      setContToken(pitchesContToken);
    }
  }, [pitchesContToken]);

  const canPage = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (!totalPitches || !pitches || !pitchesContToken) return false;
      return (
        entries[0].isIntersecting &&
        !isLoadingMore &&
        (entries[0].target as HTMLTableRowElement).rowIndex < totalPitches
      );
    },
    [totalPitches, pitches, pitchesContToken, isLoadingMore]
  );

  return (
    <>
      {trackLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      {!trackLoading && tracks?.length === 0 && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-pitches-available">No Pitches available</h4>
        </div>
      )}
      {!trackLoading && tracks?.length > 0 && (
        <div className={selectedTracks.length === tracks?.length ? 'to-do-card-border mt16 mb100' : 'card mt16 mb100'}>
          <div className={selectedTracks.length === tracks.length ? 'to-do-card' : 'p1'}>
            <PitchesTable
              tracks={tracks}
              showPlaylist
              sort={sortBy}
              orderBy={order}
              updateTracksSortBy={(e) => {
                if (e.sortBy === sortBy && e.orderBy === order) return;
                setTracksLoading(true);
                setTracks([]);
                setSortBy(e.sortBy);
                setOrder(e.orderBy);
              }}
              selectedTracks={selectedTracks}
              setSelectedTracks={setSelectedTracks}
              updateTracks={() => {
                setTracksLoading(true);
                setTracks([]);
                setLoadMoreToken('');
                setSelectedTracks([]);
                refetchPitches();
              }}
              bottomTrackIntersecting={(entries) => {
                if (canPage(entries)) {
                  setIsLoadingMore(true);
                  setLoadMoreToken(contToken);
                } else {
                  setIsLoadingMore(false);
                }
              }}
            />
            {isLoadingMore && <div className="text-center mt16">Loading more...</div>}
          </div>
        </div>
      )}
    </>
  );
}
